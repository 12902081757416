import type { IntlDateTimeFormat } from 'vue-i18n';

import datetimeDeDE from './i18n/datetime/de-DE.json';
import datetimeEnUS from './i18n/datetime/en-US.json';
import datetimeFrFR from './i18n/datetime/fr-FR.json';
import datetimeRuRU from './i18n/datetime/ru-RU.json';
import datetimeTrTR from './i18n/datetime/tr-TR.json';

const datetimeFormats = {
  de: datetimeDeDE,
  en: datetimeEnUS,
  fr: datetimeFrFR,
  ru: datetimeRuRU,
  tr: datetimeTrTR,
} as Record<string, IntlDateTimeFormat>;

export default defineI18nConfig(() => ({
  warnHtmlMessage: false,
  datetimeFormats,
  fallbackLocale: {
    'xx-XX': [],
  },
}));
